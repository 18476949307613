import React, { useState, useRef } from "react";
import { getImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import BlogPostCard from "./BlogPostCard";

const RelatedPosts = ({ articles }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef();

  return (
    <section className="pb-20 pt-10 md:pb-28 md:pt-16">
      <div className="container">
        <div className="row">
          <div className="mx-auto md:col-10">
            <h3 className="mb-6 text-center text-[32px] font-bold leading-10 md:mb-8 md:text-left">
              Noticias relacionadas
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="mx-auto !px-0 md:col-10">
            <Slider
              slidesToShow={3}
              arrows={false}
              ref={sliderRef}
              responsive={[{ breakpoint: 900, settings: { slidesToShow: 1 } }]}
              beforeChange={(_, next) => setCurrentIndex(next)}
            >
              {articles.map((article) => (
                <BlogPostCard
                  key={article.id}
                  className="px-4 pb-6"
                  title={article.title}
                  description={article.description}
                  category={article.category}
                  date={article.publish_date}
                  link={`/blog/${article.slug}`}
                  image={getImage(article.image.imageFile)}
                />
              ))}
            </Slider>

            <ul className="mt-10 w-full text-center md:mt-12">
              {articles.map((_, i) => (
                <li className="mx-1 inline-block h-fit">
                  <button
                    onClick={() => sliderRef.current.slickGoTo(i)}
                    className={`h-4 w-4 rounded-full transition-colors duration-300 border border-[#697276] ${
                      currentIndex === i ? "bg-[#697276]" : "bg-white"
                    }`}
                  >
                    <span className="sr-only">{i + 1}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelatedPosts;
