import React from "react";
import { Link } from "gatsby";

import { Layout } from "../layout/Layout";
import ShareButton from "../components/common/ShareButton";
import ArrowBack from "@/assets/icons/arrow-back.inline.svg";
import RelatedPosts from "../components/common/RelatedPosts";

import { formatDate } from "../utils";

const BlogPost = ({ pageContext }) => {
  const { article, relatedArticles, prevArticleSlug, nextArticleSlug } = pageContext;

  return (
    <Layout pageId="product-detail">
      <div className="container border-b-2 border-[#303030] border-opacity-10 lg:mt-20">
        <div className="row">
          {/* Heading */}
          <div className="md:col-12">
            <div className="mb-6 flex items-center justify-between md:mb-10">
              <Link className="flex items-center gap-2 font-bold" to="/blog">
                <ArrowBack className="h-5 w-5 fill-[#303030]" />
                Regresar
              </Link>
              <ShareButton />
            </div>
            <div className="mb-4 flex items-center justify-center gap-2 text-[#697276] md:mb-6">
              <span>{article.category}</span>
              <span className="text-[#00AAA6]">|</span>
              <span className="text-sm">{formatDate(article.publish_date)}</span>
            </div>
            <h1 className="mx-auto mb-8 max-w-4xl text-center text-3xl font-bold md:mb-12 md:text-4xl">
              {article.title}
            </h1>
          </div>
          {/* Content */}
          <div className="mx-auto pb-12 md:col-10 md:pb-24">
            <div
              className="prose mx-auto max-w-[864px] prose-headings:text-[#303030] prose-p:font-light prose-img:rounded-2xl"
              dangerouslySetInnerHTML={{ __html: article.body }}
            />
          </div>
          {/* Navigation Buttons */}
          <div className="mx-auto md:col-10">
            <div className="mb-10 flex items-center justify-between md:mb-20">
              <Link
                className={`flex items-center gap-2 font-bold ${!prevArticleSlug && "text-[#697276]"}`}
                to={prevArticleSlug && `/blog/${prevArticleSlug}`}
              >
                <ArrowBack className={prevArticleSlug ? "fill-[#303030]" : "fill-[#697276]"} />
                Noticia anterior
              </Link>
              <Link
                className={`flex items-center gap-2 font-bold ${!nextArticleSlug && "text-[#697276]"}`}
                to={nextArticleSlug && `/blog/${nextArticleSlug}`}
              >
                Siguiente noticia
                <ArrowBack className={`rotate-180 ${nextArticleSlug ? "fill-[#303030]" : "fill-[#697276]"}`} />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <RelatedPosts articles={relatedArticles} />
    </Layout>
  );
};

export default BlogPost;
